import React from "react";
import { ThemeProvider } from "@fluentui/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import getTheme from "./getTheme";
import { msalConfig } from "./msalConfig";

const msalInstance = new PublicClientApplication(msalConfig);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function rootElementWrapper({ element }: any): JSX.Element {
    return (
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={getTheme()}>{element}</ThemeProvider>
        </MsalProvider>
    );
}

export default rootElementWrapper;
