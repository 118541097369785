import { ITheme, loadTheme } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";

initializeIcons();

const getTheme = (): ITheme => {
    return loadTheme({
        palette: {
            themePrimary: `#913338`,
            themeLighterAlt: `#fbf4f4`,
            themeLighter: `#edd5d6`,
            themeLight: `#deb3b5`,
            themeTertiary: `#bd7377`,
            themeSecondary: `#9f4448`,
            themeDarkAlt: `#832e32`,
            themeDark: `#6e272a`,
            themeDarker: `#511c1f`,
            neutralLighterAlt: `#e2e0d9`,
            neutralLighter: `#dedcd5`,
            neutralLight: `#d5d3cd`,
            neutralQuaternaryAlt: `#c6c5be`,
            neutralQuaternary: `#bdbcb6`,
            neutralTertiaryAlt: `#b6b4af`,
            neutralTertiary: `#bab8b7`,
            neutralSecondary: `#a3a2a0`,
            neutralPrimaryAlt: `#8d8b8a`,
            neutralPrimary: `#323130`,
            neutralDark: `#605e5d`,
            black: `#494847`,
            white: `#e8e6df`,
            green: `#7dba00`,
            greenLight: `#86c440`,
        },
        defaultFontStyle: { fontFamily: "Montserrat, Tahoma, Geneva, sans-serif", fontWeight: "normal" },
    });
};

export default getTheme;
