module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-0421cfd0c2/0/cache/gatsby-plugin-image-npm-2.12.1-80c5f12561-f12008e18c.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-093bc5b49b/0/cache/gatsby-remark-images-npm-6.12.1-84f21bbc0a-2e1160e5be.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"showCaptions":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3fc132a20a/0/cache/gatsby-plugin-manifest-npm-4.12.1-3e09126b44-9ca0935b27.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"demos.datascenarios.nl","short_name":"demosite","start_url":"/","background_color":"#e8e6df","theme_color":"#6e272a","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"00f6f781d82495142dbae908210561f5"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
