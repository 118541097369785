export const msalConfig = {
    auth: {
        clientId: process.env.GATSBY_MSAL_CLIENTID as string,
        authority: process.env.GATSBY_MSAL_AUTHORITY,
        redirectUri: process.env.GATSBY_MSAL_REDIRECTURI,
        validateAuthority: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ["User.Read"],
};

export const pbiRequest = {
    scopes: ["https://analysis.windows.net/powerbi/api/.default"],
};
