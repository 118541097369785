exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-embedded-example-tsx": () => import("./../../../src/pages/embedded-example.tsx" /* webpackChunkName: "component---src-pages-embedded-example-tsx" */),
  "component---src-pages-embedded-interaction-tsx": () => import("./../../../src/pages/embedded-interaction.tsx" /* webpackChunkName: "component---src-pages-embedded-interaction-tsx" */),
  "component---src-pages-embedded-tsx": () => import("./../../../src/pages/embedded.tsx" /* webpackChunkName: "component---src-pages-embedded-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-main-tsx": () => import("./../../../src/pages/main.tsx" /* webpackChunkName: "component---src-pages-main-tsx" */),
  "component---src-pages-secure-example-tsx": () => import("./../../../src/pages/secure-example.tsx" /* webpackChunkName: "component---src-pages-secure-example-tsx" */),
  "component---src-pages-secure-tsx": () => import("./../../../src/pages/secure.tsx" /* webpackChunkName: "component---src-pages-secure-tsx" */)
}

